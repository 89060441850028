import ContentList from '@chipsadesign/content-list';
import { ContentListFilter } from '@chipsadesign/content-list/dist/modules/ContentListFilter';
import revealAnimations from './reveal';
import afficheClipPath from './affiche-clip-path';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

function init(container: HTMLElement | Document = document) {
    const searchResult = document.querySelector<HTMLElement>('.js-search-result');

    if (searchResult) {
        const catalogContainer = container.querySelector<HTMLElement>('.js-catalog-container');
        const filterEl = container.querySelector<HTMLElement>('.js-catalog-filter-wrapper');
        const popupFilters = filterEl?.closest<LitPopupElement>('app-lit-popup');
        const popupFiltersTriggers = popupFilters?.dataset.litPopup
            ? Array.from(
                  container.querySelectorAll<HTMLElement>(`[data-lit-popup-open="${popupFilters.dataset.litPopup}"]`),
              )
            : [];
        const filterSubmit = filterEl?.querySelector<HTMLElement>('[type="submit"]');
        const monthTriggers = container.querySelectorAll<HTMLElement>('.js-month-trigger');

        let fetchTriggerElement: HTMLElement | null = null;
        let isMonthesTrigger = false;
        monthTriggers.forEach((trigger) =>
            trigger.addEventListener('click', (event) => {
                isMonthesTrigger = true;
                fetchTriggerElement = event.currentTarget as HTMLElement;
            }),
        );

        new ContentList(searchResult, {
            modules: filterEl ? [ContentListFilter] : [],
            pushState: false,
            filter: filterEl
                ? {
                      el: filterEl,
                  }
                : undefined,
            onFetchStart: () => {
                filterSubmit?.classList.add('is-load');

                if (isMonthesTrigger) {
                    catalogContainer?.classList.add('is-loading');
                }

                monthTriggers.forEach((button) => (button.disabled = false));
            },
            onFetchSuccess: (instance, response) => {
                instance.contentEl?.classList.add('is-leave');

                if (response.data?.nextUrl) {
                    window.history.pushState(null, '', response.data.nextUrl);
                }

                if (response.forbiddenToScroll) {
                    if (fetchTriggerElement) {
                        fetchTriggerElement.disabled = true;
                    }
                }

                setTimeout(() => {
                    if (response.data) {
                        const hidden = response.data.filterHtml === '';
                        popupFiltersTriggers.forEach((el) => {
                            el.hidden = hidden;
                        });
                    }

                    if (response.data?.filterHtml && filterEl) {
                        filterEl.innerHTML = response.data.filterHtml;
                    }

                    if (response.data?.listHtml && instance.contentEl) {
                        instance.contentEl.innerHTML = response.data.listHtml;
                        instance.contentEl.classList.remove('is-leave');
                        popupFilters?.close();
                        revealAnimations.init();
                        afficheClipPath.init(instance.contentEl);
                        window.__hwmAfisha.scanTags();
                        window.dispatchEvent(new Event('hwm-afisha-scan-tags'));
                        catalogContainer?.classList.remove('is-loading');
                        filterSubmit?.classList.remove('is-load');
                        window.scrollTo({ top: 0, behavior: 'smooth' });

                        isMonthesTrigger = false;
                        fetchTriggerElement = null;
                    }
                }, 300);
            },
        });
    }
}

const _module = { init };

export default _module;
