import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';
import headerTheme from './inits/header-theme';
import footerSticky from './inits/footer-sticky';
import reveal from './inits/reveal';
import { preloader } from './inits/preloader';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { timeout } from './utils/timeout';
import formSender from './inits/form-sender';
import splitText from './inits/split-text';
import vhMobileFix from '../modules/vh-mobile-fix';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import menu from './inits/menu';
import gosPopup from './inits/gos-popup-scroll';
import sliderDetail from './inits/slider-detail';
import customCursor from './inits/custom-cursor';
import searchPopup from './inits/search-popup';
import popupToggleTrigger from './inits/popup-toggle-trigger';
import impairedVisually from './inits/impaired-visually';
import bviPanelFixed from './inits/bvi-panel-fixed';

// main page
import sliderIntro from './inits/slider-intro';
import parallaxEffect from './inits/parallax';
import sliderPartners from './inits/slider-partners';

// affiche
import afficheClipPath from './inits/affiche-clip-path';
import catalogFilters from './inits/catalog-filters';
import afficheMonthes from './inits/affiche-monthes';

// detail
import timeChanging from './inits/time-changing';
import galleryPopup from './inits/gallery-popup';

// about
import sliderTeam from './inits/slider-team';
import select from './inits/select';
import video from './inits/video';
import sliderCertificates from './inits/slider-certificates';
import gallery from './inits/gallery';

//search
import searchResult from './inits/search-result';

// purchase
import sliderFreeMode from './inits/slider-free-mode';
import chooseAllCheckboxes from './inits/choose-all-checkboxes';
import tooltip from './inits/tooltip';

// history
import dragscroll from './inits/dragscroll';
import history from './inits/history';

//collectives
import formFilters from './inits/form-filters';

document.documentElement.classList.add('js-ready');

gsap.registerPlugin(ScrollTrigger);

applyPolyfills().then(() => {
    vhMobileFix();
    calculateScrollbarWidth();
    headerTheme.init();
    footerSticky.init();
    splitText.init();
    sliderIntro.init();
    bviPanelFixed.init();

    preloader
        .loadAssets()
        .then(() => timeout(1500))
        .then(() => preloader.leave())
        .then(() => {
            reveal.init();
            formSender.init();
            menu.init();
            gosPopup.init();
            sliderDetail.init();
            searchPopup.init();
            popupToggleTrigger.init();
            impairedVisually.init();

            // main page
            parallaxEffect.init();
            sliderPartners.init();

            //search
            searchResult.init();

            // affiche page
            afficheClipPath.init();
            catalogFilters.init();
            afficheMonthes.init();

            // detail
            timeChanging.init();
            customCursor.init();
            galleryPopup.init();

            // about
            sliderTeam.init();
            select.init();
            video.init();
            sliderCertificates.init();
            gallery.init();

            // purchase
            sliderFreeMode.init();
            chooseAllCheckboxes.init();
            tooltip.init();

            // history
            dragscroll.init();
            history.init();

            //collectives
            formFilters.init();
        });
});

// Always at the end
// module.hot?.accept();
