function init() {
    const inputs = Array.from<HTMLInputElement>(document.querySelectorAll('.tab-item__input'));
    const btn = document.querySelector<HTMLFormElement>('.form-button');
    let selectedFilters = [];

    const updateFilter = () => {
        const selectedTypes = inputs.reduce((res, cur) => {
            if (cur.checked) {
                res.push(cur.name);
            }
            return res;
        }, [] as string[]);

        return selectedTypes;
    };

    const updateURL = () => {
        selectedFilters = updateFilter();

        const qp = new URLSearchParams();

        qp.delete('filter');

        selectedFilters.forEach((filter) => qp.append('filter', filter));

        history.replaceState(null, null, '?' + qp.toString());
    };

    btn?.addEventListener('click', () => {
        updateURL();
    });
}

const _module = { init };

export default _module;
