import gsap from 'gsap';

/**
 * letter-o-initial-x - изначальное положение фигуры в свг относительно границ
 * letter-o-hover-x - положение фигуры после ховера
 * letter-o-new-x - новое положение для определенного айтема
 */
const clipPathPositions = [
    {
        'letter-o-initial-x': 920,
        'letter-o-initial-y': 386,
        'letter-k-initial-x': 599,
        'letter-k-initial-y': 244,
        'letter-ts-initial-x': 675,
        'letter-ts-initial-y': 412,
        'letter-d-initial-x': 675,
        'letter-d-initial-y': 244,

        'letter-o-hover-x': 921,
        'letter-o-hover-y': 244,
        'letter-k-hover-x': 599,
        'letter-k-hover-y': 379,
        'letter-ts-hover-x': 892,
        'letter-ts-hover-y': 412,
        'letter-d-hover-x': 650,
        'letter-d-hover-y': 244,
    },
    {
        'letter-o-initial-x': 805,
        'letter-o-initial-y': 179,
        'letter-k-initial-x': 663,
        'letter-k-initial-y': 555,
        'letter-ts-initial-x': 831,
        'letter-ts-initial-y': 448,
        'letter-d-initial-x': 663,
        'letter-d-initial-y': 287,

        'letter-o-hover-x': 664,
        'letter-o-hover-y': 180,
        'letter-k-hover-x': 799,
        'letter-k-hover-y': 556,
        'letter-ts-hover-x': 832,
        'letter-ts-hover-y': 232,
        'letter-d-hover-x': 664,
        'letter-d-hover-y': 331,
    },
    {
        'letter-o-new-x': 726,
        'letter-o-new-y': 244,
        'letter-k-new-x': 982,
        'letter-k-new-y': 534,
        'letter-ts-new-x': 944,
        'letter-ts-new-y': 244,
        'letter-d-new-x': 512,
        'letter-d-new-y': 370,

        'letter-o-initial-x': 920,
        'letter-o-initial-y': 386,
        'letter-k-initial-x': 599,
        'letter-k-initial-y': 244,
        'letter-ts-initial-x': 675,
        'letter-ts-initial-y': 412,
        'letter-d-initial-x': 675,
        'letter-d-initial-y': 244,

        'letter-o-hover-x': 599,
        'letter-o-hover-y': 406,
        'letter-k-hover-x': 904,
        'letter-k-hover-y': 244,
        'letter-ts-hover-x': 599,
        'letter-ts-hover-y': 244,
        'letter-d-hover-x': 802,
        'letter-d-hover-y': 370,
    },
    {
        'letter-o-initial-x': 805,
        'letter-o-initial-y': 179,
        'letter-k-initial-x': 663,
        'letter-k-initial-y': 555,
        'letter-ts-initial-x': 831,
        'letter-ts-initial-y': 448,
        'letter-d-initial-x': 663,
        'letter-d-initial-y': 287,

        'letter-o-new-x': 577,
        'letter-o-new-y': 380,
        'letter-k-new-x': 867,
        'letter-k-new-y': 179,
        'letter-ts-new-x': 577,
        'letter-ts-new-y': 186,
        'letter-d-new-x': 703,
        'letter-d-new-y': 455,

        'letter-o-hover-x': 826,
        'letter-o-hover-y': 500,
        'letter-k-hover-x': 660,
        'letter-k-hover-y': 250,
        'letter-ts-hover-x': 660,
        'letter-ts-hover-y': 524,
        'letter-d-hover-x': 790,
        'letter-d-hover-y': 178,
    },
];

const clipPathStartPositions = [
    {
        'letter-o-start-x': 0,
        'letter-o-start-y': 0,
        'letter-k-start-x': 0,
        'letter-k-start-y': 0,
        'letter-ts-start-x': 0,
        'letter-ts-start-y': 0,
        'letter-d-start-x': 0,
        'letter-d-start-y': 0,
    },
    {
        'letter-o-start-x': 0,
        'letter-o-start-y': 0,
        'letter-k-start-x': 0,
        'letter-k-start-y': 0,
        'letter-ts-start-x': 0,
        'letter-ts-start-y': 0,
        'letter-d-start-x': 0,
        'letter-d-start-y': 0,
    },
    {
        'letter-o-start-x': clipPathPositions[2]['letter-o-new-x']! - clipPathPositions[2]['letter-o-initial-x'],
        'letter-o-start-y': clipPathPositions[2]['letter-o-new-y']! - clipPathPositions[2]['letter-o-initial-y'],
        'letter-k-start-x': clipPathPositions[2]['letter-k-new-x']! - clipPathPositions[2]['letter-k-initial-x'],
        'letter-k-start-y': clipPathPositions[2]['letter-k-new-y']! - clipPathPositions[2]['letter-k-initial-y'],
        'letter-ts-start-x': clipPathPositions[2]['letter-ts-new-x']! - clipPathPositions[2]['letter-ts-initial-x'],
        'letter-ts-start-y': clipPathPositions[2]['letter-ts-new-y']! - clipPathPositions[2]['letter-ts-initial-y'],
        'letter-d-start-x': clipPathPositions[2]['letter-d-new-x']! - clipPathPositions[2]['letter-d-initial-x'],
        'letter-d-start-y': clipPathPositions[2]['letter-d-new-y']! - clipPathPositions[2]['letter-d-initial-y'],
    },
    {
        'letter-o-start-x': clipPathPositions[3]['letter-o-new-x']! - clipPathPositions[3]['letter-o-initial-x'],
        'letter-o-start-y': clipPathPositions[3]['letter-o-new-y']! - clipPathPositions[3]['letter-o-initial-y'],
        'letter-k-start-x': clipPathPositions[3]['letter-k-new-x']! - clipPathPositions[3]['letter-k-initial-x'],
        'letter-k-start-y': clipPathPositions[3]['letter-k-new-y']! - clipPathPositions[3]['letter-k-initial-y'],
        'letter-ts-start-x': clipPathPositions[3]['letter-ts-new-x']! - clipPathPositions[3]['letter-ts-initial-x'],
        'letter-ts-start-y': clipPathPositions[3]['letter-ts-new-y']! - clipPathPositions[3]['letter-ts-initial-y'],
        'letter-d-start-x': clipPathPositions[3]['letter-d-new-x']! - clipPathPositions[3]['letter-d-initial-x'],
        'letter-d-start-y': clipPathPositions[3]['letter-d-new-y']! - clipPathPositions[3]['letter-d-initial-y'],
    },
];

function init(container: HTMLElement | Document = document) {
    const afficheItems = container.querySelectorAll<HTMLElement>('.js-affiche-item');

    afficheItems.forEach(function (item, i) {
        const maskNumber = item.dataset.mask ? Number(item.dataset.mask) - 1 : i;

        const clipPathSelector =
            maskNumber % 4 === 0
                ? '.js-affiche-clip-path-second'
                : maskNumber % 2 === 0
                ? '.js-affiche-clip-path'
                : '.js-affiche-clip-path-second';
        const svgEl = item.querySelector(clipPathSelector);
        const clipPathId = svgEl?.id;
        const clipPathEl = item.querySelector<HTMLElement>('.js-affiche-pic');

        const link = item.querySelector('a');
        const maskLetterK = svgEl?.querySelector<HTMLElement>('.js-affiche-letter-k');
        const maskLetterD = svgEl?.querySelector<HTMLElement>('.js-affiche-letter-d');
        const maskLetterTs = svgEl?.querySelector<HTMLElement>('.js-affiche-letter-ts');
        const maskLetterO = svgEl?.querySelector<HTMLElement>('.js-affiche-letter-o');

        let indexEl: number;

        const setNewPosition = (index: number) => {
            if (!maskLetterD || !maskLetterO || !maskLetterTs || !maskLetterK) return;
            const tl = gsap.timeline({
                defaults: {
                    duration: 0.8,
                    ease: 'cubic-bezier(0.6, 0.06, 0.6, 0.94)',
                },
            });

            tl.set(
                maskLetterTs,
                {
                    x: clipPathStartPositions[index]['letter-ts-start-x'],
                    y: clipPathStartPositions[index]['letter-ts-start-y'],
                },
                0,
            )
                .set(
                    maskLetterK,
                    {
                        x: clipPathStartPositions[index]['letter-k-start-x'],
                        y: clipPathStartPositions[index]['letter-k-start-y'],
                    },
                    0,
                )
                .set(
                    maskLetterO,
                    {
                        x: clipPathStartPositions[index]['letter-o-start-x'],
                        y: clipPathStartPositions[index]['letter-o-start-y'],
                    },
                    0,
                )
                .set(
                    maskLetterD,
                    {
                        x: clipPathStartPositions[index]['letter-d-start-x'],
                        y: clipPathStartPositions[index]['letter-d-start-y'],
                    },
                    0,
                );
        };

        if (clipPathEl && clipPathId) {
            clipPathEl.style.clipPath = `url(#${clipPathId})`;
        }

        if (maskNumber % 4 === 0) {
            item.classList.add('is-fourth');

            indexEl = 3;
            setNewPosition(3);
        } else if ((maskNumber + 3) % 4 === 0) {
            item.classList.add('is-second');
            indexEl = 1;
        } else if ((maskNumber + 2) % 4 === 0) {
            item.classList.add('is-third');

            indexEl = 2;
            setNewPosition(2);
        } else if ((maskNumber + 1) % 4 === 0) {
            item.classList.add('is-fourth');
            indexEl = 3;

            setNewPosition(3);
        }

        if (maskLetterD && maskLetterO && maskLetterTs && maskLetterK) {
            link?.addEventListener('mouseenter', () => {
                const tl = gsap.timeline({
                    defaults: {
                        duration: 0.8,
                        ease: 'cubic-bezier(0.6, 0.06, 0.6, 0.94)',
                    },
                });

                tl.to(
                    maskLetterTs,
                    {
                        x:
                            clipPathPositions[indexEl]['letter-ts-hover-x'] -
                            clipPathPositions[indexEl]['letter-ts-initial-x'],
                        y:
                            clipPathPositions[indexEl]['letter-ts-hover-y'] -
                            clipPathPositions[indexEl]['letter-ts-initial-y'],
                    },
                    0,
                )
                    .to(
                        maskLetterK,
                        {
                            x:
                                clipPathPositions[indexEl]['letter-k-hover-x'] -
                                clipPathPositions[indexEl]['letter-k-initial-x'],
                            y:
                                clipPathPositions[indexEl]['letter-k-hover-y'] -
                                clipPathPositions[indexEl]['letter-k-initial-y'],
                        },
                        0,
                    )
                    .to(
                        maskLetterO,
                        {
                            x:
                                clipPathPositions[indexEl]['letter-o-hover-x'] -
                                clipPathPositions[indexEl]['letter-o-initial-x'],
                            y:
                                clipPathPositions[indexEl]['letter-o-hover-y'] -
                                clipPathPositions[indexEl]['letter-o-initial-y'],
                        },
                        0,
                    )
                    .to(
                        maskLetterD,
                        {
                            x:
                                clipPathPositions[indexEl]['letter-d-hover-x'] -
                                clipPathPositions[indexEl]['letter-d-initial-x'],
                            y:
                                clipPathPositions[indexEl]['letter-d-hover-y'] -
                                clipPathPositions[indexEl]['letter-d-initial-y'],
                        },
                        0,
                    );
            });

            link?.addEventListener('mouseleave', () => {
                const tl = gsap.timeline({
                    defaults: {
                        duration: 0.8,
                        ease: 'cubic-bezier(0.6, 0.06, 0.6, 0.94)',
                    },
                });

                tl.to(
                    maskLetterTs,
                    {
                        x: clipPathStartPositions[indexEl]['letter-ts-start-x'],
                        y: clipPathStartPositions[indexEl]['letter-ts-start-y'],
                    },
                    0,
                )
                    .to(
                        maskLetterK,
                        {
                            x: clipPathStartPositions[indexEl]['letter-k-start-x'],
                            y: clipPathStartPositions[indexEl]['letter-k-start-y'],
                        },
                        0,
                    )
                    .to(
                        maskLetterO,
                        {
                            x: clipPathStartPositions[indexEl]['letter-o-start-x'],
                            y: clipPathStartPositions[indexEl]['letter-o-start-y'],
                        },
                        0,
                    )
                    .to(
                        maskLetterD,
                        {
                            x: clipPathStartPositions[indexEl]['letter-d-start-x'],
                            y: clipPathStartPositions[indexEl]['letter-d-start-y'],
                        },
                        0,
                    );
            });
        }
    });
}

const _module = { init };

export default _module;
