function init() {
    const footer = document.querySelector<HTMLElement>('.js-footer');
    const stickySection = document.querySelector<HTMLElement>('.js-sticky-section');
    const stickySectionInner = stickySection?.querySelector<HTMLElement>('.js-sticky-section-inner');
    if (!footer || !stickySection || !stickySectionInner) return;

    const setStickySectionSizes = () => {
        if (window.matchMedia('(min-width: 1200px)').matches) {
            const stickySectionHeight = stickySectionInner.clientHeight;
            const footerHeight = footer.clientHeight;
            const windowHeight = window.innerHeight;

            stickySection.style.minHeight = `${stickySectionHeight + footerHeight}px`;
            stickySection.style.marginBottom = `${-footerHeight}px`;

            stickySectionInner.style.position = 'sticky';
            stickySectionInner.style.top = `${windowHeight - stickySectionHeight}px`;
        } else {
            stickySection.style.minHeight = '';
            stickySection.style.marginBottom = '';
            stickySectionInner.style.position = '';
            stickySectionInner.style.top = '';
        }
    };

    setStickySectionSizes();

    const debouncedSetStickySectionSizes = debounce(setStickySectionSizes, 100);
    window.addEventListener('resize', debouncedSetStickySectionSizes);
}

const debounce = <T extends (...args: unknown[]) => void>(func: T, wait: number) => {
    let timeout: NodeJS.Timeout;
    return function (this: unknown, ...args: Parameters<T>) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
};

const _module = { init };

export default _module;
