const changeFiltersForm = (activeMonth: HTMLInputElement, activeYear: number | null) => {
    const form = document.querySelector<HTMLFormElement>('.js-affiche-filters-form');
    if (!form) return;

    const formSubmit = form.querySelector<HTMLInputElement>('[type="submit"]');
    const monthInput = form.querySelector<HTMLInputElement>('.js-filters-month-input');
    const yearInput = form.querySelector<HTMLInputElement>('.js-filters-year-input');

    if (monthInput) {
        monthInput.value = activeMonth.value;
    }

    if (yearInput && activeYear) {
        yearInput.value = `${activeYear}`;
    }

    formSubmit?.click();
};

function setMaxMonthLength(array, wrapper) {
    const maxWidth = getMaxElementWidth(array);

    if (wrapper) {
        wrapper.style.minWidth = `${maxWidth}px`;
    }

    function getMaxElementWidth(array) {
        if (!array.length) return 0;

        const widths = Array.from(array).map((el) => el.offsetWidth);
        const maxWidth = Math.max(...widths);
        return maxWidth;
    }
}

function init() {
    const monthTriggers = document.querySelectorAll<HTMLElement>('.js-month-trigger');
    const monthInputs = Array.from(document.querySelectorAll<HTMLInputElement>('.js-filters-month-input-trigger'));
    const yearContainer = document.querySelector<HTMLInputElement>('.js-filters-year');
    const monthContainer = document.querySelector<HTMLInputElement>('.js-filters-month');
    const monthTitles = document.querySelectorAll<HTMLInputElement>('.js-affiche-month__text');

    const updateMonthContainer = () => {
        if (monthTitles.length > 1 && monthContainer) {
            setMaxMonthLength(monthTitles, monthContainer);
        }
    };

    updateMonthContainer();
    window.addEventListener('resize', updateMonthContainer);

    if (monthInputs.length == 0) return;

    const changeActiveItem = (e: Event) => {
        const activeMonth = monthInputs.find((item) => item.checked);
        const target = e.currentTarget as HTMLElement;
        const isNext = target.classList.contains('is-next');

        if (activeMonth) {
            const activeMonthIndex = monthInputs.indexOf(activeMonth);

            let nextActiveIndex = 0;
            if (isNext) {
                nextActiveIndex = activeMonthIndex == monthInputs.length - 1 ? 0 : activeMonthIndex + 1;
            } else {
                nextActiveIndex = activeMonthIndex == 0 ? monthInputs.length - 1 : activeMonthIndex - 1;
            }

            activeMonth.checked = false;
            activeMonth.classList.remove('is-active');
            monthInputs[nextActiveIndex].checked = true;

            setTimeout(() => {
                monthInputs[nextActiveIndex].classList.add('is-active');
            }, 300);

            // меняем год
            let activeYear = null;
            if ((isNext && activeMonthIndex == monthInputs.length - 1) || (!isNext && activeMonthIndex == 0)) {
                if (yearContainer) {
                    yearContainer.classList.add('is-hide');
                    activeYear = isNext ? Number(yearContainer.textContent) + 1 : Number(yearContainer.textContent) - 1;
                    yearContainer.textContent = `${activeYear}`;

                    setTimeout(() => {
                        yearContainer.classList.remove('is-hide');
                    }, 300);
                }
            }

            changeFiltersForm(monthInputs[nextActiveIndex], activeYear);
        }
    };

    monthTriggers.forEach((trigger) => trigger.addEventListener('click', changeActiveItem));
}

const _module = { init };

export default _module;
